<template>
    <div class="tooltip-container">
        <div  class="a2round">?</div>
        <div class="tooltip">
            <span
                class="text"
            >
                {{ text }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "Tooltip",
    props: { 
        text: {
            type: String,
            required: true
        }
    },
}
</script>
<style scoped>
.tooltip-container { 
  position: relative;
  display: inherit;
}
/* .tooltip-container:hover .tooltip{
  opacity: 1;
} */
.a2round:hover + .tooltip {
    opacity: 1;
}
.tooltip { 
  color: #ffffff;
  text-align: left;
  padding: 5px 8px;
  border-radius: 4px;
  max-width: 250px;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  z-index: 1;
  background: #0E5DA7;
  bottom: 30px;
}
.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #0E5DA7 transparent transparent transparent;
}
.a2round {
    padding: 0;
    margin: 0;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-color: #fff;
    color: #0071D9;
    display: block;
    border-radius: 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    border: 1px solid #BCE0FF;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
}
@media screen and (max-width: 480px) {
    .tooltip {
        right: 0!important;
        left: auto;
    }
    .text::after {
        display: none;
    }
}
</style>