let inputPricing = {
    "root": {
        "Quote": {
              "Geo_Postal_Code": null,
              "Insured_List": [
                  {
                      "Marital_Status": null,
                      "Accidents": null,
                      "Age": null,
                      "Gender": null,
                      "Driving_Experience": null,
                      "DOB": null,
                      "Occupation": null,
                      "Occupation_Group": null,
                      "Is_Main_Insured": "1"
                  }
              ],
              // "Tentative_Start_Date": "Other",
  
              "Covers": [],
              "Policy_Renewal_Invite_Count": "0",
              // "Radar_Call_Type": Global.motorVehicle.radarCallType,
              // "Policy_Number": Global.motorVehicle.quote.Opportunity_Number,
              "Policy_Number": null, //<----- will change
              "Quote_Channel_First_Contact": "",
              "Quote_Date_of_Quote": null,
              "Policy_End_Date": "",
              // "Prem_Details_Renewal_Adjustment_Pct": "",
              "Endorsement_Effective_Date": null,
              "PreviousCovers": [],
              "Quote_Date_of_First_Quote": null,
              "Quote_Date_of_First_NB_Quote": "",
              // "Quote_To_Opp_Scored": "",
              "Policy_POI_Changed_Flag": "No",
              "Policy_Payment_Frequency" : "",
              // "Question_Set_Code": "1",
  
              "Health_Questions": [],
              "Transaction_Type": "New Biz",
              "Current_Policy_Start_Date": "",
              "Endorsement_Number": "0",
              "Claims": [],
              "Vehicle_Data": {
                  "Ownership": "Private",
                  "Odometer_Reading_Date": "",
                  "Manufacture_Year": null,
                  // "Red_Book_List_Value_GoodRetail": String(Global.motorVehicle.carDetails?.finalValue),
                  "Default_Value": null,
                  "Redbook_SI" : null,
                  "Adjusted_SI" : null,
                  "Usage_Type": null,
                  // "UW_Vehicle_Type_Update_Flag": "No",
                  "Red_Book_List_FamilyCode": null,
                  "Asset_Odometer_Reading_Date": "",
                  "Red_Book_List_SeatCapacity": null,
                  "Asset_Odometer_Reading": "",
                  "Red_Book_List_Power": null,
                  // "Red_Book_List_SeatHeight": "0",
                  "Cubic_Capacity": null,
                  // "Nb_Seats": String(Global.motorVehicle.carDetails?.seatCapacity),
                  "Odometer_Reading": null,
                  // "Red_Book_List_Value_AvgWholesale": "",
                  // "Policy_Estimated_Annual_Mileage": "",
                  // "Red_Book_List_Value_AvgRetail": "",
                  "Car_Plate_Province": null,
                  // "Red_Book_List_EngineDescription": "",
                  "Red_Book_List_Value_NewPrice": null,
  
                  // "UW_Vehicle_Type_Redbook": "",
  
                  "UW_Premium_Group": null,
                  "Fault_Incurred": "",
                  "UW_Premium_Class": null,
                  "UW_Vehicle_Type": null,
                  "Red_Book_List_Vehicle_Type_Code": null,
                  "Vehicle_Accessories": [],
                  // "Usage_By_Owner": "1",
                  // "Accessory_Total_Value": "0",
                  "Red_Book_List_BodyStyleDescription": null,
                  "Fault_Claims_For_Rating": "",
                  // "Adjusted_Value": "0",
                  // "UW_Group": "",
                  "Red_Book_List_KerbWeight": null
              },
              "Policy_Endorsement_Type": "New Biz",
              "Policy_Auto_renewal_Flag": "No",
              "Endorsement_Cancellation_Reason": "",
              "Policy_Start_Date": null,
              "Endorsement_Reason": "",
              "Product_Type": "MotorCar"
        }
    }
}

export default inputPricing;