import inputPricing from "./inputPricingObj.js";
import {removeQuoteData, listObj , removeInsuredData} from "./removingObj";
import { formattedDate,dayMonthYearFormatedDate } from "../lib/index.js";
import Global from "@/store/global.js";
import { policyObject } from "./PolicyObject.js";

export function createInputPricingObj(){

    let todayDate = formattedDate();
    let quote = inputPricing.root.Quote;
    
    quote.Quote_Date_of_Quote = todayDate;
    // quote.Endorsement_Effective_Date = todayDate;
    const atmoment = new Date();
    quote.Quote_Date_of_First_Quote = formattedDate(atmoment.toISOString());

    let accessoryIndx = quote.Vehicle_Data.Vehicle_Accessories.findIndex(accessory => {
        return accessory.vehicleAccessoryName == "Car_Camera";
    })
    if(accessoryIndx == null || accessoryIndx == undefined || accessoryIndx < 0){
        let vehicleAccessories = {};
        vehicleAccessories.vehicleAccessoryName = "Car_Camera";
        vehicleAccessories.vehicleAccessoryValue = policyObject.root.quoteData.vehicleData.carAccessoryCarCamera === "Yes" ? "1" : "0";
        quote.Vehicle_Data.Vehicle_Accessories.push(vehicleAccessories);
    }else{
        quote.Vehicle_Data.Vehicle_Accessories[accessoryIndx].vehicleAccessoryValue = policyObject.root.quoteData.vehicleData.carAccessoryCarCamera === "Yes" ? "1" : "0";
    }
    // console.log(Global.brokerInfo.useInstallment);
    if (Global.isAgentCustomerView && !Global.brokerInfo.useInstallment) {
        quote.Policy_Payment_Frequency = 'Yearly';
    }
    
    quote.Insured_List[0].DOB = dayMonthYearFormatedDate(policyObject.root.quoteData.insuredData.dob,"month")
    quote.Policy_Start_Date = policyObject.root.quoteData.startDate;
    mappingObject(pricingInputMappingObj)
    return inputPricing;
}

export function updatePolicyObjAfterRetrievePolicy(quote){

    mappingObject(pricingInputMappingObjAfterRetrievePolicy, quote)
}

export function removingObject(policy){
    for (let i = 0; i < listObj.length; i++) {
        const element = listObj[i];
        for(var s = 0; s < removeInsuredData.length; s++) {
            if(policy[element].hasOwnProperty(removeInsuredData[s])) {
                delete policy[element][removeInsuredData[s]];
            }
        }
        if (element == 'insuredData') {
            delete policy[element].PersonHasOptedOutOfEmail;
        }
    }
    for(var s = 0; s < removeQuoteData.length; s++) {
        if(policy.hasOwnProperty(removeQuoteData[s])) {
            delete policy[removeQuoteData[s]];
        }
    }
    policy.insuredData.thaiIdNumber = (policy.insuredData.thaiIdNumber == null ? "" : policy.insuredData.thaiIdNumber);
    policy.insuredData.email = (policy.insuredData.email == null ? "" : policy.insuredData.email);
    policy.insuredData.firstName = (policy.insuredData.firstName == null ? "" : policy.insuredData.firstName);
    policy.insuredData.lastName = (policy.insuredData.lastName == null ? "" : policy.insuredData.lastName == "Unknown" ? "" : policy.insuredData.lastName);
    policy.policyHolderData.lastName = (policy.policyHolderData.lastName == null ? "" : policy.policyHolderData.lastName == "Unknown" ? "" : policy.policyHolderData.lastName);
    policy.vehicleData.chassisNumber = (policy.vehicleData.chassisNumber == null ? "" : policy.vehicleData.chassisNumber);
    policy.vehicleData.carPlateNumber = (policy.vehicleData.carPlateNumber == null ? "" : policy.vehicleData.carPlateNumber);
    policy.policyHolderData.thaiIdNumber = (policy.policyHolderData.thaiIdNumber == null ? "" : policy.policyHolderData.thaiIdNumber);
    policy.policyHolderData.contactAddresses[0].soi = (policy.policyHolderData.contactAddresses[0].soi == null ? "" : policy.policyHolderData.contactAddresses[0].soi);
    policy.policyHolderData.contactAddresses[0].houseNumber = (policy.policyHolderData.contactAddresses[0].houseNumber == null ? "" : policy.policyHolderData.contactAddresses[0].houseNumber);
    policy.policyHolderData.contactAddresses[0].village = (policy.policyHolderData.contactAddresses[0].village == null ? "" : policy.policyHolderData.contactAddresses[0].village);
    return policy
}

function mappingObject(mapObj, quote) {

    if (mapObj != null && mapObj != undefined) {
  
        let sourceObj = null;
        let targetObj = null;
        Object.keys(mapObj).forEach(mappingKey => {
        if(quote != null){
            const quoteRetrieve = {"quoteData" :quote} 
            sourceObj = quoteRetrieve;
            targetObj = inputPricing.root;
        }else{
            sourceObj = policyObject.root;
            targetObj = inputPricing.root;
        }

        if (mapObj[mappingKey] != null && mapObj[mappingKey] != undefined) {
          let policyObjPath = mapObj[mappingKey].ObjectPath.policyServiceObjectPath;
          let inputTargetObjPath = mapObj[mappingKey].ObjectPath.targetObjectPath;
          let isArray = mapObj[mappingKey].ObjectPath.isArray;
          let policyObjKeyArr = policyObjPath.split(".");
          let targetKeyArr = inputTargetObjPath.split(".");
  
          targetKeyArr.forEach(key => {
  
            targetObj = targetObj[key];
  
          })
  
          policyObjKeyArr.forEach(key => {
  
            sourceObj = sourceObj[key];
  
          })
  
          if (isArray) {
            
            if(Array.isArray(sourceObj) && Array.isArray(targetObj)){
                for (let indx in sourceObj) {
                    mapFields(sourceObj[indx],
                      targetObj[indx],
                      mapObj[mappingKey].fieldsToMap);
                  }
            }
            if(!Array.isArray(sourceObj) && Array.isArray(targetObj)){

                for (let indx in targetObj) {
                    mapFields(sourceObj,
                      targetObj[indx],
                      mapObj[mappingKey].fieldsToMap);
                  }
            }
            if(Array.isArray(sourceObj) && !Array.isArray(targetObj)){               
                for (let indx in targetObj) {
                    mapFields(sourceObj[indx],
                      targetObj,
                      mapObj[mappingKey].fieldsToMap);
                  }
            }
            
          } else {
            mapFields(sourceObj,
              targetObj,
              mapObj[mappingKey].fieldsToMap);
          }
  
        }
  
      })
  
    }
  
  }
  
  function mapFields(sourceObj, targetObj, fieldMapping) {
    
    Object.entries(fieldMapping).forEach(([key, value]) => {
      targetObj[key] = (sourceObj[value] != null) ? new String(sourceObj[value]) : "";
    });
  }

const pricingInputMappingObj = {
    "quoteData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData",
            "targetObjectPath": "Quote",
            "isArray": false
        },
        "fieldsToMap": {
            "Geo_Postal_Code": "postalCode",
            "Transaction_Type" : "transactionType",
            "Policy_Number": "opportunityNumber",
            "Transaction_Type" : "transactionType",
        },
    },
    "vehicleData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData.vehicleData",
            "targetObjectPath": "Quote.Vehicle_Data",
            "isArray": false
        },
        "fieldsToMap": {
            "Manufacture_Year": "yearOfManufacture",
            "Default_Value": "vehicleSumInsured",
            "Redbook_SI": "vehiclePrice",
            "Adjusted_SI": "adjustedVehicleSumInsured",
            "Usage_Type": "vehicleUsage",
            "Red_Book_List_FamilyCode": "familyCode",
            "Red_Book_List_SeatCapacity": "seatCapacity",
            "Red_Book_List_Power": "power",
            "Cubic_Capacity": "engineSize",
            "Odometer_Reading": "odometerReading",
            "Car_Plate_Province": "carPlateProvince",
            "Red_Book_List_Value_NewPrice": "newPrice",
            "UW_Premium_Group": "uwPremiumGroup",
            "UW_Premium_Class": "uwPremiumClass",
            "UW_Vehicle_Type": "voluntaryType",
            "Red_Book_List_Vehicle_Type_Code": "vehicleTypeCode",
            "Red_Book_List_BodyStyleDescription": "bodyStyle",
            "Red_Book_List_KerbWeight": "kerbWeight",
            "Ownership": "carOwnerShip"
        }
    },
    "insuredData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData.insuredData",
            "targetObjectPath": "Quote.Insured_List",
            "isArray": true
        },
        "fieldsToMap": {
            "Marital_Status": "maritialStatus",
            "Accidents": "driverAccidents",
            "Age": "driverAge",
            "Gender": "gender",
            "Driving_Experience": "drivingExperience",
            // "DOB": "dob",
        }
    }
};

const pricingInputMappingObjAfterRetrievePolicy = {
    "quoteData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData",
            "targetObjectPath": "Quote",
            "isArray": false
        },
        "fieldsToMap": {
            "Geo_Postal_Code": "postalCode",
            "Policy_Number": "opportunityNumber",
            "Policy_End_Date" : "endDate",
            "Transaction_Type" : "transactionType",
            "productVersionNo": "productVersionNo",
            "Policy_Renewal_Invite_Count": "renewalNumber",
            "Policy_Auto_renewal_Flag": "flagAutoRenewal",
            "Policy_Start_Date": "startDate",
            "Quote_Date_of_First_Quote": "quoteCreatedDate",
            "Endorsement_Effective_Date":"endorseEffectiveDate",
            // "currentPolicyExpiryDate":"Policy_Quote_Expiry_Date",
            // "previousInsurer":"Car_Previous_Insurer",
            // "previousCoverType":"Policy_Previous_Cover_Type",
            // "status":"Policy_Status",
            // "channel":"Policy_Channel_Payment",//Quote_Channel_First_Contact
            "Product_Type": "productType",
            "Approved_Code": "approvedCode",
            "Question_Set_Code": "questionSetCode",
            "Endorsement_Reason": "endorseReason",
            "Endorsement_Cancellation_Method": "calcMethod",
            "Payment_Decision": "paymentDecision"
        },
    },
    "vehicleData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData.vehicleData",
            "targetObjectPath": "Quote.Vehicle_Data",
            "isArray": false
        },
        "fieldsToMap": {
            "Manufacture_Year": "yearOfManufacture",
            "Default_Value": "vehicleSumInsured",
            "Usage_Type": "vehicleUsage",
            "Redbook_SI": "vehiclePrice",
            "Adjusted_SI": "adjustedVehicleSumInsured",
            "Red_Book_List_FamilyCode": "familyCode",
            "Red_Book_List_SeatCapacity": "seatCapacity",
            "Red_Book_List_Power": "power",
            "Cubic_Capacity": "engineSize",
            "Odometer_Reading": "odometerReading",
            "Car_Plate_Province": "carPlateProvince",
            "Red_Book_List_Value_NewPrice": "newPrice",
            "UW_Premium_Group": "uwPremiumGroup",
            "UW_Premium_Class": "uwPremiumClass",
            "UW_Vehicle_Type": "voluntaryType",
            "Red_Book_List_Vehicle_Type_Code": "vehicleTypeCode",
            "Red_Book_List_BodyStyleDescription": "bodyStyle",
            "Red_Book_List_KerbWeight": "kerbWeight",
            "Ownership": "carOwnerShip",
            "Redbook_SI": "vehiclePrice",
            "Adjusted_SI": "adjustedVehicleSumInsured"
        }
    },
    "insuredData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData.insuredData",
            "targetObjectPath": "Quote.Insured_List",
            "isArray": false
        },
        "fieldsToMap": {
            "maritialStatus": "maritialStatus",
            "driverAccidents": "driverAccidents",
            "driverAge": "driverAge",
            "gender": "gender",
            "drivingExperience": "drivingExperience",
            "dob": "dob",
        }
    }
};

export const policyServiceMappingObj = {
    "quoteData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData",
            "inputPriceResponseObjectPath": "Quote",
            "isArray": false
        },
        "fieldsToMap": {
            "rewardPoint": "Reward_Point"
        },
    },
    "vehicleData": {
        "ObjectPath": {
            "policyServiceObjectPath": "quoteData.vehicleData",
            "inputPriceResponseObjectPath": "Quote.Vehicle_Data",
            "isArray": false
        },
        "fieldsToMap": {
            "flagInspectionRequired": "Vehicle_Inspection_Required",
            "inspectionMethod": "Vehicle_Inspection_Method",
            "inspectionLimit": "Vehicle_Inspection_Limit",
            "renewalNumber":"Policy_Renewal_Invite_Count",
            "driverPlan": "Driver_Plan",
            "workShopType": "Workshop",
            "planType": "Plan_Type",
            "excess": "Excess"
        }
    }
};
